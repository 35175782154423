import React, { Component } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import queryString from 'query-string';
import moment from 'moment';
import axios from 'axios';
import DatePicker from './components/DatePicker';
import SearchBar from './components/SearchBar';
import Timeline from './components/Timeline';
import AbsenceLegend from './components/AbsenceLegend';
import TimelineNavigation from './components/TimelineNavigation';

import './App.scss';
import 'moment/locale/en-gb';

moment.locale('en-gb');

// the library is bugged if users is empty initially
const initialUsers = [
  {
    id: 1,
    title: 'Loading...'
  }
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingTableInfo: false,
      fetchingUserInfo: true,
      users: initialUsers,
      absences: [],
      startTime: moment().startOf('week').valueOf(),
      endTime: moment().endOf('week').valueOf(),
      searchValue: '',
      filterSubordinates: false,
      hideReasons: false,
      loading: true,
      userInfo: null,
      fetchError: false,
      loadedStart: moment().startOf('month').add(-1, 'month'),
      loadedEnd: moment().endOf('month').add(1, 'month'),
      companyHolidays: [],
      holidays: [],
      visibleUsers: [],
      visibleAbsences: [],
      isSupervisor: false,
      larpId: null,
      showOnlyAbsences: true,
      emptyUsers: new Set([]),
      absenceCodes: []
    };

    this.onBoundsChange = this.onBoundsChange.bind(this);
    this.handleTimelineZoom = this.handleTimelineZoom.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubordinatesChange = this.handleSubordinatesChange.bind(this);
    this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
    this.handleAbsenceFilterChange = this.handleAbsenceFilterChange.bind(this);
    this.handleHideReasonsChange = this.handleHideReasonsChange.bind(this);
    this.parseUrlQuery = this.parseUrlQuery.bind(this);
    this.isSupervisor = this.isSupervisor.bind(this);
    this.pushStateToUrl = this.pushStateToUrl.bind(this);
    this.getLarpSuffix = this.getLarpSuffix.bind(this);
  }

  componentDidMount() {
    this.getUserData();
    this.fetchInformation();
  }

  getLarpSuffix() {
    const { search } = this.props.location;
    const values = queryString.parse(search);

    const larpId = values.userId;
    return larpId ? `?userId=${larpId}` : '';
  }

  getUserData() {
    const larp = this.getLarpSuffix();

    axios
      .get(`/api/v1/user/${larp}`)
      .then(({ data }) =>
        this.setState(
          {
            userInfo: data,
            fetchingUserInfo: false,
            isSupervisor: data.isSupervisor
          },
          () => this.parseUrlQuery(data.isSupervisor)
        )
      )
      .catch(() => this.setState({ fetchError: true }));
  }

  parseUrlQuery(defaultSubFilter) {
    const { search } = this.props.location;

    const values = queryString.parse(search);

    const filterSubordinates = values['filter-subordinates']
      ? values['filter-subordinates'] === 'true'
      : defaultSubFilter;
    const searchValue = values.search || '';
    const { userId } = values;
    const absences = values['only-absences'] !== 'false';
    const hideReasons = values['hide-reasons'] === 'true';

    this.setState(
      {
        searchValue,
        filterSubordinates,
        larpId: userId,
        hideReasons,
        showOnlyAbsences: absences
      },
      () => this.refreshVisibles()
    );
  }

  convertDatesToTimes(startDate, endDate) {
    return [
      parseInt(moment(startDate).startOf('day').format('x'), 10),
      parseInt(moment(endDate).endOf('day').format('x'), 10)
    ];
  }

  convertTimesToDates(startTime, endTime) {
    return [
      moment(startTime).format('YYYY-MM-DD'),
      moment(endTime).format('YYYY-MM-DD')
    ];
  }

  handleUsersToItems(data) {
    const ret = {
      users: [],
      absences: []
    };

    /**
     * absences are objects of shape {date: ..., type: ...}
     * there is a type only if user is in current user's team
     */
    Object.entries(data).forEach(
      ([userId, { absences, firstName, lastName, supervisorId }]) => {
        ret.users.push({
          id: userId,
          title: `${firstName} ${lastName}`,
          supervisorId
        });

        absences.forEach((absence) => {
          // cant use converter because this one wants milliseconds as strings
          const startTime = moment(absence.date).startOf('day').format('x');
          const endTime = moment(absence.date).endOf('day').format('x');

          const id = `${userId}/${absence.date}`;

          ret.absences.push({
            id,
            title: absence.type ? absence.type[0] : '',
            group: userId,
            start_time: startTime,
            end_time: endTime,
            canMove: false,
            canResize: false,
            className: 'timeline--item',
            canChangeGroup: false,
            style: {
              backgroundColor: 'black',
              color: 'black'
            }
          });
        });
      }
    );

    return ret;
  }

  fetchInformation() {
    if (this.state.fetchingTableInfo) return;

    const { startTime, endTime } = this.state;

    const startDate = moment(startTime).add(-1, 'month');
    const endDate = moment(endTime).add(1, 'month');

    const formattedStartDate = startDate.format('YYYY-MM-DD');
    const formattedEndDate = endDate.format('YYYY-MM-DD');

    this.setState({
      fetchingTableInfo: true,
      loadedEnd: endDate,
      loadedStart: startDate
    });

    const larp = this.getLarpSuffix();

    axios
      .get(`/api/v1/holidays/${formattedStartDate}/${formattedEndDate}/`)
      .then(({ data }) => {
        const holidays = data.map((d) => d.date);
        this.setState({ holidays });
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e));

    return axios
      .get(
        `/api/v1/holidays/holiday_data/${formattedStartDate}/${formattedEndDate}/${larp}`
      )
      .then(({ data }) => {
        const { users, absences } = this.handleUsersToItems(data.users);
        const emptyUsers = new Set(data.empty_users);
        const absenceCodes = Object.values(data.absence_codes);

        this.setState(
          {
            users,
            absences,
            fetchingTableInfo: false,
            emptyUsers,
            absenceCodes
          },
          () => this.refreshVisibles()
        );
      })
      .catch(() => this.setState({ fetchError: true }));
  }

  isSupervisor() {
    const { userInfo } = this.state;
    return userInfo && userInfo.isSupervisor ? userInfo.isSupervisor : false;
  }

  handleDateChange(startDate, endDate) {
    const [startTime, endTime] = this.convertDatesToTimes(startDate, endDate);

    this.setState({
      endTime,
      startTime
    });
  }

  handleSearchValueChange(e) {
    const searchValue = e.target.value;

    this.setState(
      {
        searchValue
      },
      () => {
        this.refreshVisibles();
        this.pushStateToUrl();
      }
    );
  }

  filterUsers() {
    let users = null;
    const {
      searchValue,
      filterSubordinates,
      userInfo,
      showOnlyAbsences,
      emptyUsers
    } = this.state;

    if (searchValue) {
      const searches = searchValue
        .split(',')
        .map((s) => s.trim().toLowerCase())
        .filter((s) => s !== '');

      users = this.state.users.filter((user) => {
        const title = user.title.toLowerCase();

        return searches.some((search) => title.includes(search));
      });
    } else {
      users = this.state.users;
    }

    if (filterSubordinates && userInfo) {
      users = users.filter(
        (user) =>
          user.supervisorId === this.state.userInfo.id ||
          parseInt(user.id) === this.state.userInfo.id
      );
    }

    if (showOnlyAbsences) {
      users = users.filter((user) => !emptyUsers.has(parseInt(user.id)));
    }

    return users;
  }

  filterAbsences(users) {
    const userIds = new Set(users.map((u) => u.id));

    const { absences, hideReasons } = this.state;

    const filtered = absences.filter((a) => userIds.has(a.group));

    if (hideReasons) {
      return filtered.map((absence) => {
        const { title, ...rest } = absence;
        return rest;
      });
    }

    this.setState({ loading: false });

    return filtered;
  }

  handleSubordinatesChange() {
    const newValue = !this.state.filterSubordinates;

    this.setState(
      {
        filterSubordinates: newValue
      },
      () => {
        this.refreshVisibles();
        this.pushStateToUrl();
      }
    );
  }

  handleHideReasonsChange() {
    const newValue = !this.state.hideReasons;

    this.setState(
      {
        hideReasons: newValue
      },
      () => {
        this.refreshVisibles();
        this.pushStateToUrl();
      }
    );
  }

  pushStateToUrl() {
    const { push } = this.props.history;
    const {
      isSupervisor,
      larpId,
      filterSubordinates,
      searchValue,
      showOnlyAbsences,
      hideReasons
    } = this.state;

    let search = isSupervisor
      ? `?search=${searchValue}&filter-subordinates=${filterSubordinates}&only-absences=${showOnlyAbsences}&hide-reasons=${hideReasons}`
      : `?search=${searchValue}&only-absences=${showOnlyAbsences}&hide-reasons=${hideReasons}`;

    if (larpId) {
      search = isSupervisor
        ? `?search=${searchValue}&filter-subordinates=${filterSubordinates}&only-absences=${showOnlyAbsences}&userId=${larpId}&hide-reasons=${hideReasons}`
        : `?search=${searchValue}&only-absences=${showOnlyAbsences}&userId=${larpId}&hide-reasons=${hideReasons}`;
    }

    push({
      pathname: '/absences',
      search
    });
  }

  onBoundsChange() {
    const { startTime, endTime, loadedStart, loadedEnd } = this.state;

    if (startTime < loadedStart || endTime > loadedEnd) {
      this.fetchInformation();
    }
  }

  handleTimelineZoom(startTime, endTime) {
    this.setState({
      startTime: this.toInt(startTime),
      endTime: this.toInt(endTime)
    });
  }

  toInt(int) {
    return parseInt(int, 10);
  }

  withTotalAbsences(absences) {
    const ret = [...absences];
    const grouped = groupBy(absences, (absence) => absence.id.split('/')[1]);

    Object.entries(grouped).forEach(([date, absences]) => {
      const startTime = moment(date).startOf('day');
      const endTime = moment(date).endOf('day');

      ret.push({
        id: `total-absence-${date}`,
        group: 99999,
        start_time: startTime,
        end_time: endTime,
        title: `${absences.length}`,
        className: 'total-absence',
        canMove: false,
        canResize: false,
        canChangeGroup: false
      });
    });

    return ret;
  }

  sortUsers(users) {
    const ret = [...users];
    ret.sort((a, b) => a.title.localeCompare(b.title));

    ret.push({
      id: 99999,
      title: 'Total absences'
    });

    return ret;
  }

  refreshVisibles() {
    const visibleUsers = this.sortUsers(this.filterUsers());

    const visibleAbsences = this.withTotalAbsences(
      this.filterAbsences(visibleUsers)
    );

    this.setState({
      visibleUsers,
      visibleAbsences
    });
  }

  handleAbsenceFilterChange(e) {
    this.setState(
      {
        showOnlyAbsences: e.target.checked
      },
      () => {
        this.refreshVisibles();
        this.pushStateToUrl();
      }
    );
  }

  render() {
    const {
      searchValue,
      filterSubordinates,
      isSupervisor,
      showOnlyAbsences,
      startTime,
      endTime,
      holidays,
      visibleUsers,
      visibleAbsences,
      companyHolidays,
      absenceCodes,
      hideReasons,
      loading
    } = this.state;

    return (
      <div className="App">
        <div className="mainpage--control-container">
          <SearchBar
            inputValue={searchValue}
            handleSearchValueChange={this.handleSearchValueChange}
            handleSubordinatesChange={this.handleSubordinatesChange}
            handleAbsenceFilterChange={this.handleAbsenceFilterChange}
            handleHideReasonsChange={this.handleHideReasonsChange}
            subordinateFilterValue={filterSubordinates}
            hideReasons={hideReasons}
            isSupervisor={isSupervisor}
            showOnlyAbsences={showOnlyAbsences}
          />

          <DatePicker
            startDate={moment(startTime)}
            endDate={moment(endTime)}
            handleDateChange={this.handleDateChange}
          />
        </div>

        <div className="timeline--container">
          {isSupervisor && <AbsenceLegend codes={absenceCodes} />}

          {loading ? (
            <div />
          ) : (
            <>
              <TimelineNavigation
                handleDateChange={this.handleDateChange}
                startTime={startTime}
                endTime={endTime}
              />

              <Timeline
                holidays={holidays}
                filteredUsers={visibleUsers}
                absences={visibleAbsences}
                startTime={startTime}
                endTime={endTime}
                onBoundsChange={this.onBoundsChange}
                handleTimelineZoom={this.handleTimelineZoom}
                companyHolidays={companyHolidays.map((h) =>
                  moment(h.date).format('YYYY-MM-DD')
                )}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default App;

App.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};
