/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Map } from 'immutable';
import DayPicker from 'react-day-picker';
import ImportBox from './ImportBox';
import 'react-day-picker/lib/style.css';
import './Calendar.scss';

export default class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null
    };

    this.goToDate = this.goToDate.bind(this);
    this.fetchMonth = this.fetchMonth.bind(this);
  }

  goToDate(date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    const months = this.props.hours.getIn(['data', 'months']).toArray();
    const days = months.reduce((l, month) => {
      return l.concat(Object.keys(month.get('days').toObject()));
    }, []);

    if (days.findIndex((m) => m === dateString) === -1) {
      if (moment(date) < moment()) {
        this.props.fetchPastHours(date);
      } else {
        this.fetchMonth(moment(date).endOf('month').toDate());
      }
    } else {
      this.props.scrollToDay(moment(date).format('YYYY-MM-DD'));
    }
  }

  fetchMonth(date) {
    if (moment(date) < moment()) {
      this.props.fetchPastHours(moment(date).endOf('month').toDate());
    } else {
      this.props.fetchFutureHours(moment(date).endOf('month').toDate());
    }
    this.props.scrollToDay(moment(date).endOf('month').format('YYYY-MM-DD'));
  }

  render() {
    let modifiers = {};
    const { user } = this.props;
    const absencesPath = user.getIn(['data', 'hrLarp'])
      ? `/absences?userId=${user.getIn(['data', 'id'])}`
      : '/absences';
    const hoursReportPath = user.getIn(['data', 'hrLarp'])
      ? `/hours-report?userId=${user.getIn(['data', 'id'])}`
      : '/hours-report';

    if (this.props.hours.getIn(['data', 'months'])) {
      const months = this.props.hours.getIn(['data', 'months']).toArray();
      const days = months
        .reduce((map, month) => {
          return map.merge(month.get('days'));
        }, Map())
        .filter((day) => day !== undefined)
        .filter((day) => day.get('type') === '');

      const today = moment().startOf('day');
      const missingEntries = days
        .filter((day) => day.get('hours') === 0)
        .keySeq()
        .map((date) => new Date(date))
        .filter((date) => moment(date).startOf() < today)
        .filter(
          (date) =>
            moment(date).startOf() >=
            moment(this.props.user.getIn(['data', 'startOfService'])).startOf()
        )
        .toArray();

      const absences = days
        .filter((day) => day.get('entries').size > 0)
        .filter(
          (day) =>
            day
              .get('entries')
              .findIndex((entry) => entry.get('projectName') === 'Absences') !==
            -1
        )
        .keySeq()
        .map((date) => new Date(date))
        .toArray();

      modifiers = {
        missingEntries,
        absences
      };
    }
    const isFinaOrHr =
      this.props.user.getIn(['data', 'isFina']) ||
      this.props.user.getIn(['data', 'isHr']);
    return (
      <div className="right-panel">
        <div className="calendar">
          <DayPicker
            firstDayOfWeek={1}
            onDayClick={this.goToDate}
            onMonthChange={this.fetchMonth}
            modifiers={modifiers}
            enableOutsideDays
          />
          <div className="legend">
            <span className="label missing-entry">Missing entry</span>
            <br />
            <span className="label absence">Absence</span>
          </div>
        </div>
        <div className="manual-link">
          <a
            href="https://confluence.remedy.fi/x/IIBw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-external-link" aria-hidden="true" />
            TimeEgg User Manual
          </a>
        </div>
        <div className="manual-link">
          <a href={absencesPath} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-external-link" aria-hidden="true" />
            Absences
          </a>
        </div>
        <div className="manual-link">
          <a href={hoursReportPath} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-external-link" aria-hidden="true" />
            Hours breakdown
          </a>
        </div>

        {isFinaOrHr && (
          <>
            <ImportBox
              upload={this.props.uploadOverworkReport}
              status={this.props.uploader.get('overworkReport')}
              label="Overtime pay import"
              downloadUrl="api/v1/import/overwork_report"
              hideExport
            />
            <br />
          </>
        )}

        {this.props.user.getIn(['data', 'isHr']) && (
          <>
            <ImportBox
              upload={this.props.uploadHolidays}
              status={this.props.uploader.get('holidays')}
              label="Import holidays"
              downloadUrl="api/v1/template/download_holiday_template"
            />
            <br />

            <ImportBox
              upload={this.props.uploadHours}
              status={this.props.uploader.get('hours')}
              label="Import flex hours"
              downloadUrl="api/v1/template/download_crunch_template"
            />
            <br />

            <ImportBox
              upload={this.props.uploadOvertimeDeltas}
              status={this.props.uploader.get('overtimeDeltas')}
              label="Import overtime deltas"
              downloadUrl="api/v1/template/download_overtime_template"
            />
            <br />
          </>
        )}

        <div className="manual-link">
          <a href="/api/v1/saml/?slo" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-external-link" aria-hidden="true" />
            Logout
          </a>
        </div>

        <div className="manual-link">
          <p>{`v ${process.env.REACT_APP_VERSION}`}</p>
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  scrollToDay: PropTypes.func.isRequired,
  fetchFutureHours: PropTypes.func.isRequired,
  fetchPastHours: PropTypes.func.isRequired,
  hours: PropTypes.instanceOf(Map).isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  uploadHolidays: PropTypes.func.isRequired,
  uploadOverworkReport: PropTypes.func.isRequired,
  uploadHours: PropTypes.func.isRequired,
  uploadOvertimeDeltas: PropTypes.func.isRequired,
  uploader: PropTypes.instanceOf(Map).isRequired
};
