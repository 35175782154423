import React, { useState, useEffect, useCallback } from 'react';
import './HoursReport.scss';
import moment from 'moment';
import axios from 'axios';
import { shape, string } from 'prop-types';
import InformationTooltip from './InformationTooltip';

const DATE_FORMAT = 'DD.MM.YYYY';
const API_DATE_FORMAT = 'YYYY-MM-DD';

const HoursReport = ({ location }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [singleMonthView, setSingleMonthView] = useState(false);
  const [data, setData] = useState(null);

  const rangeStart = moment().subtract(1, 'days').subtract(4, 'months');
  const rangeEnd = moment().subtract(1, 'days');

  const getLarpId = useCallback(() => {
    const { search } = location;

    const larpId = search
      // Remove ? from the beginning
      .substr(1)
      .split('&')
      .map((s) => {
        const [key, value] = s.split('=');

        if (key === 'userId') {
          return value;
        }
      })
      .filter((s) => s);

    return larpId;
  }, [location]);

  useEffect(() => {
    const { search } = location;

    const parsedSearch = search
      // Remove ? from the beginning
      .substr(1)
      .split('&')
      .reduce((acc, str) => {
        const [key, value] = str.split('=');

        if (key === 'startDate' || key === 'endDate') {
          return { ...acc, [key]: value };
        }

        return acc;
      }, {});

    if (Object.keys(parsedSearch).length === 0) {
      setEndDate(rangeEnd);
      setStartDate(rangeStart);
    } else {
      setStartDate(moment(parsedSearch.startDate, DATE_FORMAT));
      setEndDate(moment(parsedSearch.endDate, DATE_FORMAT));
      setSingleMonthView(true);
    }
  }, [location]);

  useEffect(() => {
    if (startDate && endDate) {
      const larpId = getLarpId();
      const larpUrlSuffix = larpId ? `?userId=${larpId}` : '';

      axios
        .get(
          `/api/v1/user/hours-report/${startDate.format(
            API_DATE_FORMAT
          )}/${endDate.format(API_DATE_FORMAT)}/${larpUrlSuffix}`
        )
        .then((resp) => setData(resp.data))
        // eslint-disable-next-line no-console
        .catch((resp) => console.log({ resp }));
    }
  }, [startDate, endDate, getLarpId]);

  const links = () => {
    const larpId = getLarpId();
    const larpUrlSuffix = larpId ? `&userId=${larpId}` : '';

    const links = [...Array(4).keys()]
      .map((i) => {
        const month = rangeEnd.clone().add(-i, 'months');

        return (
          <a
            style={{ margin: '1px 0' }}
            key={i}
            href={`/hours-report?startDate=${month
              .startOf('month')
              .format(DATE_FORMAT)}&endDate=${month
              .endOf('month')
              .format(DATE_FORMAT)}${larpUrlSuffix}`}
          >
            {month.format('MMMM YYYY')}
          </a>
        );
      })
      .reverse();

    const rangeLink = (
      <a style={{ margin: '1px 0' }} key="range" href="/hours-report">
        {`${rangeStart.format('MMMM YYYY')} - ${rangeEnd.format('MMMM YYYY')}`}
      </a>
    );

    return singleMonthView ? [rangeLink].concat(links) : links;
  };

  const reportTable = () => {
    const {
      flexStartBalance,
      flexEndBalance,
      flexUsed,
      flexAdded,
      flexDelta,
      holidaysSpent,
      newHolidaysSum,
      holidaysStartBalance,
      holidaysNow,
      overworkDone,
      overworkUsed,
      overworkBalanceAtStart,
      expectedHours,
      totalWorkDone,
      overworkPaid
    } = data;

    const overWorkRow = (percent) => {
      const delta = overworkDone[percent] + overworkUsed[percent];
      return (
        <tr>
          <th>{percent} %</th>
          <td>{overworkBalanceAtStart[percent]}</td>
          <td>{overworkDone[percent]}</td>
          <td>{overworkUsed[percent]}</td>
          <td>{delta}</td>
          <td>{overworkBalanceAtStart[percent] + delta}</td>
          <td>{percent / 100}</td>
          <td>{overworkDone[percent] * (parseInt(percent, 10) / 100)}</td>
          <td>{overworkPaid[percent] * (parseInt(percent, 10) / 100)}</td>
        </tr>
      );
    };

    // 🔆🌻🌤😎
    const percentSummer = () => {
      const PERCENTS = ['0', '50', '100'];

      const startSum = PERCENTS.reduce(
        (acc, curr) => acc + overworkBalanceAtStart[curr],
        0
      );
      const doneSum = PERCENTS.reduce(
        (acc, curr) => acc + overworkDone[curr],
        0
      );
      const usedSum = PERCENTS.reduce(
        (acc, curr) => acc + overworkUsed[curr],
        0
      );
      const deltaSum = doneSum + usedSum;
      const currBalanceSum = startSum + deltaSum;

      const bonusTotal = PERCENTS.reduce(
        (acc, curr) => acc + (parseInt(curr, 10) / 100) * overworkDone[curr],
        0
      );

      const paidTotal = PERCENTS.reduce(
        (acc, curr) => acc + (parseInt(curr, 10) / 100) * overworkPaid[curr],
        0
      );

      return [
        startSum + flexStartBalance,
        doneSum + flexAdded,
        usedSum + flexUsed,
        deltaSum + flexDelta,
        currBalanceSum + flexEndBalance,
        null,
        bonusTotal,
        paidTotal
      ];
    };

    const totalRow = () => {
      const tds = percentSummer().map((sum, i) => (
        <td key={`${sum} + ${i}`}>{sum}</td>
      ));

      return (
        <tr>
          <th>Total</th>
          {tds}
        </tr>
      );
    };

    return (
      <table>
        <thead>
          <tr>
            <th>{`Report period: ${startDate.format(
              DATE_FORMAT
            )} - ${endDate.format(DATE_FORMAT)}`}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Report period assumed hours</td>
            <td>{expectedHours}</td>
            <td>Hours sum</td>
            <td>{totalWorkDone}</td>
          </tr>

          <tr>
            <th>
              Work type
              <InformationTooltip>
                <div>
                  Specifies the type of work
                  <h4>Flex</h4>
                  <div>
                    Flextime balance over or under your regular working hours
                  </div>
                  <h4>0%</h4>
                  <div>
                    During overtime scope only. Additional work. In practice
                    work done during overtime scope that is not overtime nor
                    your regular work, is additional work.
                  </div>
                  <h4>50%</h4>
                  <div>
                    During overtime scope only. Overtime for the first 2
                    overtime hours on your normal workday and the weekly
                    overtime hours. On a regular workday, the calculation starts
                    at after 8 daily hours and ends at 10 daily hours.
                  </div>
                  <h4>100%</h4>
                  <div>
                    During overtime scope only. Further daily overtime hours
                    after the +50% hours. On a regular workday, the calculation
                    starts after 10 daily hours.
                  </div>
                </div>
              </InformationTooltip>
            </th>
            <th>
              Start balance
              <InformationTooltip>
                <div>Your balance at {startDate.format(DATE_FORMAT)}</div>
              </InformationTooltip>
            </th>
            <th>
              Additions
              <InformationTooltip>
                <div>Additional hours gained during the period</div>
              </InformationTooltip>
            </th>
            <th>
              Reduces
              <InformationTooltip>
                <div>
                  Hours used in the respective category during report period
                </div>
              </InformationTooltip>
            </th>
            <th>
              Change
              <InformationTooltip>
                <div>Additions and reductions combined, total change.</div>
              </InformationTooltip>
            </th>
            <th>
              End balance
              <InformationTooltip>
                <div>Your balance at {endDate.format(DATE_FORMAT)}</div>
              </InformationTooltip>
            </th>
            <th>
              Extra multiplier
              <InformationTooltip>
                <div>
                  Multiplier used to calculate the multiplied hours from
                  overtime hours done.
                </div>
              </InformationTooltip>
            </th>
            <th>
              Multiplied hours
              <InformationTooltip>
                <div>
                  <div>
                    Hours calculated from overtime done x the multiplier (either
                    the +50% or +100% hours).
                  </div>
                  <div>
                    These hours are paid out to you and are not usable as free
                    time. The base part (=the actual hours you do) of overtime
                    is usable as free time.
                  </div>
                </div>
              </InformationTooltip>
            </th>
            <th>
              Hours paid out
              <InformationTooltip>
                <div>
                  Amount of already paid out multiplied hours as of reporting
                  day.
                </div>
              </InformationTooltip>
            </th>
          </tr>

          <tr>
            <th>Flex</th>
            <td>{flexStartBalance}</td>
            <td>{flexAdded}</td>
            <td>{flexUsed}</td>
            <td>{flexDelta}</td>
            <td>{flexEndBalance}</td>
            <td>0</td>
            <td>0</td>
            <td />
          </tr>

          {overWorkRow('0')}
          {overWorkRow('50')}
          {overWorkRow('100')}
          {totalRow()}

          <tr>
            <th>
              Holidays
              <InformationTooltip>
                <div>
                  Holidays are added in a centralized manner by HR when the
                  holiday accumulation season closes. Additions to holidays are
                  not shown here, only holidays kept are shown.
                </div>
              </InformationTooltip>
            </th>
            <td>{holidaysStartBalance}</td>
            <td>{newHolidaysSum}</td>
            <td>{holidaysSpent}</td>
            <td>{newHolidaysSum - holidaysSpent}</td>
            <td>{holidaysNow}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EFF2F3',
        fontSize: 'large',
        flexDirection: 'column'
      }}
    >
      <div>
        {data && reportTable()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '30px',
            width: 'max-content'
          }}
        >
          {startDate && data && links()}
        </div>
        <div />
      </div>
    </div>
  );
};

HoursReport.propTypes = {
  location: shape({ search: string }).isRequired
};

export default HoursReport;
