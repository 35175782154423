/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'react-select2-wrapper';

import $ from 'jquery';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useLocation } from 'react-router-dom';
import ReadOnlyInput from '../ReadOnlyInput';

import './Exporter.scss';
import useHideOnOutsideClick from '../../hooks/use-hide-on-outside-click';

/**
 * class exported without default for easing tests
 */
const Exporter = ({ hr, fina, hide }) => {
  const initEndDate = moment().subtract(1, 'months').endOf('month');
  const initStartDate = moment().subtract(1, 'months').startOf('month');
  const ref = useRef(null);

  const [startDate, setStartDate] = useState(
    initStartDate.format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(initEndDate.format('YYYY-MM-DD'));
  const [balancesDate, setBalancesDate] = useState(endDate);
  const [personecDate, setPersonecDate] = useState(endDate);
  const [personecType, setPersonecType] = useState('holidays');
  const [includeSubTeams, setIncludeSubTeams] = useState(false);
  const [includeDisabled, setIncludeDisabled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    appendSelectors();
  });

  useHideOnOutsideClick(ref, hide);

  const appendSelectors = () => {
    // Dirty hack, please forgive me.
    // This replaces the default selection arrows with FontAwesome arrows.
    // The default ones are hidden with css
    if (!ref?.current) return;
    const elements = $(ref.current).find('.date-input-wrapper');

    for (let i = 0; i < elements.length; i++) {
      const element = $(elements[i]);
      if (element.find('.fa-angle-down').length === 0) {
        element.append('<i class="fa fa-angle-down"></i>');
      }
    }
  };

  // const getSelectOptions = () => {
  //   const date = moment('2017-01');
  //   const list = [];

  //   while (date < moment()) {
  //     list.push({ id: list.length, text: date.format('MMMM YYYY') });
  //     date.add(1, 'month');
  //   }

  //   return list;
  // };

  const toggleSubTeams = () => {
    setIncludeSubTeams((prev) => !prev);
  };

  const toggleDisabled = () => {
    setIncludeDisabled((prev) => !prev);
  };

  const onStartChange = (event) => {
    setStartDate(moment(event).format('YYYY-MM-DD'));
  };

  const onEndChange = (event) => {
    setEndDate(moment(event).format('YYYY-MM-DD'));
  };

  const onBalancesChange = (event) => {
    setBalancesDate(moment(event).format('YYYY-MM-DD'));
  };

  const onPersonecDateChange = (event) => {
    setPersonecDate(moment(event).format('YYYY-MM-DD'));
  };

  const onPersonecTypeChange = (event) => {
    setPersonecType(event.target.value);
  };

  const getLarpQuery = () => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const userId = searchParams.get('userId');

    if (userId) {
      return `?userId=${userId}`;
    }

    return '';
  };

  const handleExport = () => {
    window.open(
      `api/v1/export/${startDate}/${endDate}/${balancesDate}/${includeSubTeams}/${includeDisabled}/${getLarpQuery()}`
    );
    hide();
  };

  const finaExport = () => {
    window.open(
      `api/v1/export/fina_overwork_report/${startDate}/${endDate}/${includeDisabled}/${getLarpQuery()}`
    );
    hide();
  };

  const exportPersonec = () => {
    window.open(
      `api/v1/export/personec/${personecType}/${personecDate.substring(
        0,
        7
      )}/${getLarpQuery()}`
    );
    hide();
  };

  return (
    <div className="exporter" ref={ref}>
      <div className="input-group">
        <label className="label">From</label>
        <br />
        <DayPickerInput
          className="date-picker"
          name="end-date"
          placeholder="YYYY-MM-DD"
          format="YYYY-MM-DD"
          value={startDate}
          onDayChange={onStartChange}
          component={ReadOnlyInput}
          dayPickerProps={{ firstDayOfWeek: 1 }}
        />
      </div>

      <div className="input-group">
        <label className="label">To</label>
        <br />
        <DayPickerInput
          className="date-picker"
          name="end-date"
          placeholder="YYYY-MM-DD"
          format="YYYY-MM-DD"
          value={endDate}
          onDayChange={onEndChange}
          component={ReadOnlyInput}
          dayPickerProps={{ firstDayOfWeek: 1 }}
        />
      </div>

      <div className="input-group">
        <label className="label">Balances</label>
        <br />
        <DayPickerInput
          className="date-picker"
          name="end-date"
          placeholder="YYYY-MM-DD"
          format="YYYY-MM-DD"
          value={balancesDate}
          onDayChange={onBalancesChange}
          component={ReadOnlyInput}
          dayPickerProps={{ firstDayOfWeek: 1 }}
        />
      </div>

      <div className="input-group">
        <div className="checkbox">
          {hr || fina ? (
            <span>
              <input
                id="includeDisabled"
                type="checkbox"
                value={includeDisabled}
                onClick={toggleDisabled}
              />
              <label htmlFor="includeDisabled"> Include disabled</label>
            </span>
          ) : (
            <span>
              <input
                id="includeSubteams"
                type="checkbox"
                value={includeSubTeams}
                onClick={toggleSubTeams}
              />
              <label htmlFor="includeSubteams"> Include subteams</label>
            </span>
          )}
        </div>
        <br />
        <br />
        <button
          className="exporter-button"
          type="submit"
          onClick={handleExport}
          style={{ margin: '0 5px' }}
        >
          Export
        </button>

        <button
          className="exporter-button"
          type="submit"
          onClick={finaExport}
          style={{ margin: '0 5px' }}
        >
          Overtime export
        </button>
      </div>

      <br />
      <br />

      {hr || fina ? (
        <div>
          <div className="input-group">
            <label className="label">Month</label>
            <br />
            <DayPickerInput
              className="date-picker"
              name="personec-date"
              placeholder="YYYY-MM-DD"
              format="YYYY-MM-DD"
              value={personecDate}
              onDayChange={onPersonecDateChange}
              component={ReadOnlyInput}
              dayPickerProps={{ firstDayOfWeek: 1 }}
            />
          </div>
          <div className="input-group">
            <label className="label">Type</label>
            <br />
            <Select2
              className="hours"
              value={personecType}
              data={[
                { id: 'holidays', text: 'Holidays' },
                { id: 'absences', text: 'Absences' },
                { id: 'overwork', text: 'Overwork' }
              ]}
              onChange={onPersonecTypeChange}
              options={{ minimumResultsForSearch: Infinity }}
            />
          </div>
          <div className="input-group">
            <button
              className="exporter-button"
              type="submit"
              onClick={exportPersonec}
              style={{ margin: '0 5px' }}
            >
              Personec export
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

Exporter.propTypes = {
  hr: PropTypes.bool.isRequired,
  fina: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired
};

export default Exporter;
